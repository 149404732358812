import { moveRefundCheckDocument } from '@/api/tablet';
import { useMutation, useQueryClient } from 'react-query';

export const useMoveRefundCheck = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      prevTabletId,
      nextTabletID,
    }: {
      userId: string;
      prevTabletId: string;
      nextTabletID: string;
    }) => moveRefundCheckDocument(userId, prevTabletId, nextTabletID),

    onSuccess: (_, variables) => {
      const { userId } = variables;
      queryClient.invalidateQueries(['refund_check', userId]);
    },

    onError: (error) => {
      console.error('문서 이동 중 오류 발생:', error);
    },
  });
};
