import { Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function MedicalTabletWaitModal({ isOpen, onClose }: Props) {
  const handleSave = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} hasBackdrop>
      <Modal.Header>
        <Title>고객 확인이 완료되지 않았어요</Title>
      </Modal.Header>
      <Content>
        <Discrption>태블릿에서 확인 버튼을 눌러주세요.</Discrption>
      </Content>
      <Modal.Footer footerStyle={{ justifyContent: 'center' }}>
        <Modal.Button
          style={{ width: '240px', height: '42px' }}
          children={'계속 진행하기'}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
}

const Title = styled.h3`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #030303;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 18px;
`;

const Content = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 476px;
  box-sizing: border-box;
`;

const Discrption = styled.p`
  color: var(--Mono-65, #5f6165);
  text-align: center;

  /* WebBody/16_MD */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
`;
export default MedicalTabletWaitModal;
