import { createRefund } from '@/api/tablet';
import { useMutation } from 'react-query';

type CreateRefundVariables = {
  totalAmount: number;
  refundAmount: number;
  finalAmount: number;
};

const useCreateRefundCheck = (userId: string, tabletId: string) => {
  return useMutation({
    mutationFn: ({
      totalAmount,
      refundAmount,
      finalAmount,
    }: CreateRefundVariables) =>
      createRefund(userId, tabletId, totalAmount, refundAmount, finalAmount),
  });
};

export default useCreateRefundCheck;
