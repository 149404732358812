import { Icon } from '@successmode-ktp/kui';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import useCreateRefundCheck from '@/hooks/useCreateRefundCheck';
import { getRefundAmount } from '@/util/getRefundAmount';
import { isCustom } from '@/constants/common';
import MedicalTabletWaitModal from '../medicalInfo/MedicalTabletWait.modal';
import MedicalTabletSettingModal from '../medicalInfo/MedicalTabletSetting.modal';
import { useMoveRefundCheck } from '@/hooks/useMoveRefundCheck';
import useRefundCheckDataSubscribe from '@/hooks/useRefundCheckDataSubscribe';
import { REFUNDSTATUS } from '@/constants/refund.const';
import useDeleteRefundCheck from '@/hooks/useDeleteRefundCheck';
import { setPrivacyAgreementDate } from '@/store/modules/refund';

function RefundCheckButton({
  totalPayPrice,
  isCustomKor,
  isDisabledRefundButton,
  onClickRefundBtn,
}: {
  totalPayPrice: number;
  isCustomKor: boolean;
  isDisabledRefundButton: boolean;
  onClickRefundBtn: () => void;
}) {
  const { currentTabletID } = useAppSelector((state) => state.tabletId);
  const { isDirectPayment, isDirectPassport } = useAppSelector(
    (state) => state.refund,
  );
  const { isAutoPayment, hospitalId } = useAppSelector((state) => state.user);
  const { mutateAsync: createRefundCheckMutate } = useCreateRefundCheck(
    hospitalId.toString(),
    currentTabletID,
  );
  const { mutateAsync: moveRefundCheckMutate } = useMoveRefundCheck();
  const { refundInfo } = useRefundCheckDataSubscribe(
    hospitalId.toString(),
    currentTabletID,
  );
  const { mutateAsync: deleteRefundCheckMutate } = useDeleteRefundCheck(
    hospitalId.toString(),
    currentTabletID,
  );
  const [isOpenTabletSettingModal, setIsOpenTabletSettingModal] =
    useState(false);

  const [isOpenTabletWaitModal, setIsOpenTabletWaitModal] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isPending = refundInfo && refundInfo?.status === REFUNDSTATUS.PENDING;
  const isSent = refundInfo && refundInfo?.status === REFUNDSTATUS.SENT;
  const isValidRefundCheckButton =
    totalPayPrice !== 0 && !isDisabledRefundButton;
  const dispatch = useAppDispatch();

  const getFinalPrice = (totalPayPrice: number) => {
    if (isCustom(hospitalId) && !isCustomKor) {
      return totalPayPrice - getRefundAmount(totalPayPrice);
    }

    if (isAutoPayment && !isDirectPayment && !isDirectPassport) {
      return totalPayPrice - getRefundAmount(totalPayPrice);
    }

    return totalPayPrice;
  };

  const passRefundCheck = () => {
    onClickRefundBtn();
  };

  const createRefundCheck = async () => {
    try {
      await createRefundCheckMutate({
        totalAmount: totalPayPrice,
        refundAmount: getRefundAmount(totalPayPrice),
        finalAmount: getFinalPrice(totalPayPrice),
      });
      console.log('Refund Check created successfully');
    } catch (error) {
      console.error('Error creating refund check:', error);
    }
  };

  const moveRefundCheck = async (nextTabletID: string) => {
    try {
      await moveRefundCheckMutate({
        userId: hospitalId.toString(),
        prevTabletId: currentTabletID,
        nextTabletID: nextTabletID,
      });
      console.log('Refund Check moved successfully');
    } catch (error) {
      console.error('Error moving refund check:', error);
    }
  };

  useEffect(() => {
    if (isPending) {
      timeoutRef.current = setTimeout(() => {
        setIsOpenTabletWaitModal(true);
      }, 20_000);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [isPending]);

  useEffect(() => {
    if (isSent) {
      setIsOpenTabletWaitModal(false);
      dispatch(setPrivacyAgreementDate(refundInfo?.privacyAgreementDate));
      onClickRefundBtn();
    }
  }, [isSent]);

  useEffect(() => {
    if (refundInfo?.id) {
      deleteRefundCheckMutate({ refundId: refundInfo.id });
    }
  }, [totalPayPrice, isDisabledRefundButton]);

  if (isPending) {
    return (
      <ButtonWrapper>
        <PassButton
          isValidate={!isSent && isValidRefundCheckButton}
          disabled={isSent || !isValidRefundCheckButton}
          onClick={passRefundCheck}
        >
          {'대면 확인 완료'}
        </PassButton>
        <PandingFakeButton>
          <LoadingButtonInner>
            <LoadingSpinner />
            고객 확인 중
            <TabletChangeButton
              onClick={() => {
                console.log('click');
                setIsOpenTabletSettingModal(true);
              }}
            >
              <Icon.Link fillColor='#D3E2FD' />
              태블릿 {currentTabletID}
            </TabletChangeButton>
          </LoadingButtonInner>
        </PandingFakeButton>
        <MedicalTabletWaitModal
          isOpen={isOpenTabletWaitModal}
          onClose={() => setIsOpenTabletWaitModal(false)}
        />
        <MedicalTabletSettingModal
          isOpen={isOpenTabletSettingModal}
          onClose={() => setIsOpenTabletSettingModal(false)}
          moveRefundCheck={moveRefundCheck}
        />
      </ButtonWrapper>
    );
  }
  return (
    <ButtonWrapper>
      {isSent ? (
        ''
      ) : (
        <PassButton
          isValidate={!isSent && isValidRefundCheckButton}
          disabled={isSent || !isValidRefundCheckButton}
          onClick={passRefundCheck}
        >
          {'대면 확인 완료'}
        </PassButton>
      )}
      <Button
        isValidate={!isSent && isValidRefundCheckButton}
        disabled={isSent || !isValidRefundCheckButton}
        onClick={createRefundCheck}
      >
        {isSent ? '고객 확인 완료' : '태블릿 확인 요청'}
      </Button>
    </ButtonWrapper>
  );
}
export default RefundCheckButton;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
const PandingFakeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 26px;
  height: 52px;
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
  background-color: ${(props) => props.theme.blue[100]};
`;

const LoadingButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;

  color: var(--Mono-00, #fff);
  text-align: center;

  /* WebTitle/16_SB */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

const LoadingSpinner = styled.div`
  width: 24px;
  height: 24px;
  border: 4px solid white;
  border-top: 4px solid #a2c2ff;
  border-radius: 50%;
  animation: spin 700ms linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const TabletChangeButton = styled.button`
  display: flex;
  flex-direction: row;

  height: 30px;
  padding: 2px 12px 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 12px;
  background: var(--Blue-80, #5089f8);

  color: var(--Blue-20, #d3e2fd);
  text-align: center;
  /* WebBody/14_MD */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.28px;
  white-space: nowrap;
`;
const PassButton = styled.button<{
  isValidate?: boolean;
  temporaryStorage?: boolean;
}>`
  width: ${(props) => (props.temporaryStorage ? '200px' : '240px')};
  height: 52px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.temporaryStorage ? props.theme.mono[80] : '#ffffff'};
  background-color: ${(props) =>
    props.temporaryStorage
      ? props.theme.mono[10]
      : props.isValidate
      ? props.theme.blue[100]
      : props.theme.blue[20]};
  :hover {
    opacity: 0.7;
  }
`;

const Button = styled.button<{
  isValidate?: boolean;
  temporaryStorage?: boolean;
}>`
  width: ${(props) => (props.temporaryStorage ? '200px' : '240px')};
  height: 52px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.temporaryStorage ? props.theme.mono[80] : '#ffffff'};
  background-color: ${(props) =>
    props.temporaryStorage
      ? props.theme.mono[10]
      : props.isValidate
      ? props.theme.blue[100]
      : props.theme.blue[20]};
  :hover {
    opacity: 0.7;
  }
`;
