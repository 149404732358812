import {
  collection,
  DocumentData,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  doc,
  deleteDoc,
  addDoc,
  getDocs,
  updateDoc,
  arrayUnion,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import { db } from '@/firebase';
import { RefundCheck } from '@/types/refund.type';
import { REFUNDSTATUS } from '@/constants/refund.const';

export function refundCheckDataSubscribe(
  userId: string,
  tabletId: string,
  callback: (refundData: RefundCheck[]) => void,
) {
  try {
    const q = query(
      collection(db, 'refund_check', userId, tabletId),
      orderBy('createdAt', 'desc'),
      limit(1),
    );

    return onSnapshot(q, (querySnapshot: QuerySnapshot<DocumentData>) => {
      const refundInfo = querySnapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as RefundCheck),
      );
      console.log('Received refund info:', refundInfo);
      callback(refundInfo);
    });
  } catch (error) {
    console.error('Error subscribing to chat rooms:', error);
    throw error;
  }
}

export async function deleteRefund(
  userId: string,
  tabletId: string,
  refundId: string,
) {
  try {
    const refundDocRef = doc(db, 'refund_check', userId, tabletId, refundId);
    await deleteDoc(refundDocRef);
    console.log(`Refund with ID ${refundId} has been deleted.`);
  } catch (error) {
    console.error('Error deleting refund:', error);
    throw error;
  }
}

export async function createRefund(
  userId: string,
  tabletId: string,
  totalAmount: number,
  refundAmount: number,
  finalAmount: number,
) {
  try {
    const refundCollectionRef = collection(
      db,
      'refund_check',
      userId,
      tabletId,
    );

    // Check if there is any existing document in the collection
    const q = query(refundCollectionRef, limit(1));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      throw new Error('A refund already exists in the collection.');
    }

    const newRefund = {
      createdAt: new Date(),
      status: REFUNDSTATUS.PENDING,
      totalAmount,
      refundAmount,
      finalAmount,
    };
    const docRef = await addDoc(refundCollectionRef, newRefund);
    console.log('Refund created with ID:', docRef.id);
    console.log('Creating refund...');
  } catch (error) {
    console.error('Error creating refund:', error);
    throw error;
  }
}

export async function updateRefundCheckState(
  userId: string,
  tabletId: string,
  refundId: string,
  newState: string,
) {
  try {
    const refundDocRef = doc(db, 'refund_check', userId, tabletId, refundId);
    await updateDoc(refundDocRef, { status: newState });
    console.log(
      `Refund with ID ${refundId} has been updated to state ${newState}.`,
    );
  } catch (error) {
    console.error('Error updating refund state:', error);
    throw error;
  }
}

export async function updateTabletList(userId: string, newTablets: string[]) {
  try {
    const tabletDocRef = doc(db, 'tablet_list', userId);
    const docSnap = await getDoc(tabletDocRef);

    if (!docSnap.exists()) {
      // 문서가 없으면 새로 생성
      await setDoc(tabletDocRef, { tablets: newTablets });
    } else {
      // 문서가 있으면 업데이트 (배열 병합)
      await updateDoc(tabletDocRef, {
        tablets: arrayUnion(...newTablets),
      });
    }
  } catch (error) {
    console.error('Error updating tablet list:', error);
    throw error;
  }
}

export async function getTabletList(userId: string) {
  try {
    console.log('Fetching tablet list...');
    const tabletDocRef = doc(db, 'tablet_list', userId);
    const tabletDocSnap = await getDoc(tabletDocRef);
    console.log('Tablet list fetched successfully', tabletDocSnap);
    if (!tabletDocSnap.exists()) {
      return ['1'];
    }

    const data = tabletDocSnap.data();
    return data.tablets || ['1'];
  } catch (error) {
    console.error('Error fetching tablet list:', error);
    return ['1'];
  }
}
export const moveRefundCheckDocument = async (
  userId: string,
  prevTabletId: string,
  nextTabletID: string,
) => {
  try {
    const sourceCollectionRef = collection(
      db,
      'refund_check',
      userId,
      prevTabletId,
    );
    const destinationCollectionRef = collection(
      db,
      'refund_check',
      userId,
      nextTabletID,
    );

    // 기존 문서 데이터 가져오기 (첫 번째 요소만)
    const sourceQuery = query(
      sourceCollectionRef,
      orderBy('createdAt', 'desc'),
      limit(1),
    );
    const sourceSnapshot = await getDocs(sourceQuery);

    if (!sourceSnapshot.empty) {
      const sourceDoc = sourceSnapshot.docs[0];
      const data = sourceDoc.data(); // 문서 데이터 가져오기

      // 새 위치에 문서가 비어있는지 확인
      const destinationQuery = query(destinationCollectionRef, limit(1));
      const destinationSnapshot = await getDocs(destinationQuery);

      if (destinationSnapshot.empty) {
        // 새 위치에 문서 저장
        await setDoc(doc(destinationCollectionRef, sourceDoc.id), data);

        // 기존 문서 삭제
        await deleteDoc(sourceDoc.ref);
        console.log('문서가 성공적으로 이동되었습니다.');
      } else {
        console.log('새 위치에 이미 문서가 존재합니다.');
      }
    } else {
      console.log('이동할 문서가 존재하지 않습니다.');
    }
  } catch (error) {
    console.error('문서 이동 중 오류 발생:', error);
  }
};
